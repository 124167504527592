import React from 'react';
import Carrental from './Carrental';
import Carrentalservices from './Carrentalservices';
import Feedback from '../../components/feedback/Feedback'
const Tourandtravels = () => {
  return (
    <div>
      <Carrental />
      <Carrentalservices />
      <Feedback />
    </div>
  )
}

export default Tourandtravels